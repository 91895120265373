import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";
import { wpImage } from "./fragments";

const indexPageData = () => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              isFrontPage
              template {
                ... on WpTemplate_FrontPage {
                  frontpageRichContent {
                    # SECTION: Hero
                    hero {
                      header
                      text
                      image {
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              width: 1800
                              placeholder: BLURRED
                              quality: 100
                            )
                          }
                        }
                      }
                    }

                    # SECTION: Our Solution
                    tabsSection {
                      header
                      text
                      slug
                      tabs {
                        label
                        content {
                          ... on WpTemplate_FrontPage_Frontpagerichcontent_TabsSection_tabs_Content_Text {
                            fieldGroupName
                            header
                            text
                            link {
                              url
                              title
                              target
                            }
                          }
                          ... on WpTemplate_FrontPage_Frontpagerichcontent_TabsSection_tabs_Content_List {
                            fieldGroupName
                            header
                            textAbove
                            textBelow
                            items {
                              text
                            }
                          }
                          ... on WpTemplate_FrontPage_Frontpagerichcontent_TabsSection_tabs_Content_Accordion {
                            fieldGroupName
                            header
                            content {
                              ... on WpTemplate_FrontPage_Frontpagerichcontent_TabsSection_tabs_Content_Accordion_Content_Text {
                                fieldGroupName
                                header
                                text
                                link {
                                  target
                                  title
                                  url
                                }
                              }
                              ... on WpTemplate_FrontPage_Frontpagerichcontent_TabsSection_tabs_Content_Accordion_Content_List {
                                fieldGroupName
                                header
                                textAbove
                                textBelow
                                items {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Single Platform
                    singlePlatform {
                      header
                      imageSections {
                        imageSection {
                          title
                          text
                          slug
                          variant
                          button {
                            target
                            url
                            title
                          }
                          image {
                            ...wpImageFluid
                          }
                        }
                      }
                    }

                    # SECTION: Market Access
                    marketAccess {
                      header
                      label
                      slug
                      list1 {
                        icon {
                          ...wpImage
                        }
                        header
                        text
                      }
                      list2 {
                        header
                        text
                        link {
                          target
                          title
                          url
                        }
                      }
                      backgroundImage {
                        ...wpImageFluid
                      }
                    }

                    # SECTION: Our Clients
                    ourClients {
                      label
                      header
                      text
                      slug
                      slider {
                        authorOffice
                        authorName
                        text
                        logo {
                          altText
                          localFile {
                            id
                            extension
                            childImageSharp {
                              gatsbyImageData(height: 48, quality: 90)
                            }

                            childSvg {
                              content {
                                data
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, "frontpage", "frontpageRichContent");
};

export { indexPageData };
