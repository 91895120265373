// Default imports
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import ButtonText from "./ButtonText";
import SectionHeader from "./SectionHeader";
import GetInTouchButton from "./GetInTouchButton";
import { FeaturesIconItem } from "./Features";
import BackgroundImage from "gatsby-background-image";

const MarketAccess = ({ header, list1, list2, backgroundImage }) => (
  <>
    <BackgroundImage
      fluid={backgroundImage?.localFile.childImageSharp?.fluid}
      className="market-access__backgroundImage"
    >
      <Container className="market-access__container">
        <div className="market-access">
          <Row>
            <Col xs="24" className="market-access__headline">
              <SectionHeader header={header} />
            </Col>

            <Col lg="10" className="market-access__mini-sections">
              {list1.map((item, index) => (
                <FeaturesIconItem fullWidth key={index} {...item} />
              ))}
            </Col>
            <Col
              lg={{ span: 12, offset: 2 }}
              className="market-access__articles"
            >
              {list2.map((item, index) => (
                <MarketAccessItem key={`artile-${index}`} {...item} />
              ))}
            </Col>
          </Row>
        </div>
      </Container>
    </BackgroundImage>
    <GetInTouchButton />
  </>
);

const MarketAccessItem = ({ header, text, link, ...props }) => (
  <div className="market-access__article" {...props}>
    <h3
      className="market-access__article-header"
      dangerouslySetInnerHTML={{ __html: header }}
    />
    <p className="market-access__article-text">{text}</p>
    <ButtonText
      className="market-access__article-button"
      variant="red"
      {...link}
    >
      {link?.title}
    </ButtonText>
  </div>
);

export default MarketAccess;
