import * as React from "react";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { Container } from "react-bootstrap";

import { indexPageData } from "../data/page-index.data";

import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import Hero from "../componentsSections/Hero";
import MarketAccess from "../components/MarketAccess";

import OurClients from "../components/OurClients";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";
import ImageSectionsGroup from "../components/ImageSectionsGroup";

import TabsSection from "../components/TabsSection";
import ConnectivityLogos from "../components/ConnectivityLogos";
import { Helmet } from "react-helmet";

// Homepage
const IndexPage = ({ data: { wpPage } }) => {
    const seoPageData = getCurrentPageSeo("frontpage");

    const pageData = indexPageData();

    return (
        <>

            <Layout transparentNavbar={!!pageData.hero.image}>
                <Seo post={seoPageData} />

                {/* Rich Hero Section */}
                <Section className="cmb-2 -mobile-full">
                    <Hero {...pageData.hero} />
                </Section>

                {/* Rich Partners Section */}
                <Section className="cmb-2">
                    <ConnectivityLogos galleryClassName="-small -center" />
                </Section>

                {/* Our Solution Section */}
                <Section>
                    <TabsSection tabsSection={pageData.tabsSection} />
                </Section>

                {/* Single Platform Section */}
                <Section className="-mobile-full">
                    <ImageSectionsGroup
                        {...pageData.singlePlatform}
                        displayLastButton
                    />
                </Section>

                {/* Rich Market Access Section */}
                <Section className="-mobile-full">
                    <MarketAccess {...pageData.marketAccess} />
                </Section>

                {/* Our Clients Section */}
                <Section>
                    <OurClients
                        containerClassName="py-0"
                        {...pageData.ourClients}
                    />
                </Section>

                {/* GetInTouch Section */}
                <Section className="-mobile-full">
                    <GetInTouch />
                </Section>

                {/* News and insights Section */}
                <Section>
                    <NewsAndInsights />
                </Section>
            </Layout>
        </>
    );
};


export default IndexPage;

export const IndexPageQuery = graphql`
    query GET_PAGE {
        wpPage(isFrontPage: { eq: true }) {
            nodeType
            title
            uri
            seo {
                title
                metaDesc
                focuskw
                metaKeywords
                metaRobotsNoindex
                metaRobotsNofollow
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    srcSet
                }
                twitterTitle
                twitterDescription
                twitterImage {
                    altText
                    sourceUrl
                    srcSet
                }
                canonical
                cornerstone
                schema {
                    articleType
                    pageType
                    raw
                }
            }
        }
    }
`;
